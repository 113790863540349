import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/legacy/image.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/carAccident.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Blocks/blocks.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Gallery/Gallery.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Hero/hero.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Button/button.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/CallToAction/cta.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Container/container.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Introduction/Introduction.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Promotion/promo.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Loader/loader.module.css");
