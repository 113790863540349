export const blurOptions = {
  cloud: {
    cloudName: "nfcpt",
  },
  transformations: {
    effect: {
      name: "pixelate",
    },
  },
};
